import React from "react";

export const Stats = () => (

    <div className="container mx-auto px-3">
      <div className="w-full flex flex-col md:flex-row">

        <div className="flex-1">
          <p className="md:mb-6 text-5xl md:text-6xl font-light ">15%</p>
          <p className="mb-2 md:mb-6 pr-6">Revenue growth for brands that improve customer experience</p>
          <p className="md:mb-6 text- ">
            <a href="https://go.forrester.com/blogs/16-06-21-customer_experience_drives_revenue_growth_2016/" className="text-" target="blank">​Forrester</a>
          </p>
        </div>

        <div className="flex-1">
          <p className="md:mb-6 text-5xl md:text-6xl font-light ">86%</p>
          <p className="mb-2  md:mb-6 pr-6">Customers with an excellent customer experience are likely to repurchase</p>
          <p className="md:mb-6 text-">
            <a href="https://www.pwc.com/us/en/advisory-services/publications/consumer-intelligence-series/pwc-consumer-intelligence-series-customer-experience.pdf" className="text-" target="blank">PwC</a>
          </p>
        </div>

        <div className="flex-1">
          <p className="md:mb-6 text-5xl md:text-6xl font-light ">33%</p>
          <p className="mb-2 md:mb-6 pr-6">Customers will leave the brand they love after just one bad experience</p>
          <p className="md:mb-6 text-">
            <a href="https://www.pwc.com/us/en/advisory-services/publications/consumer-intelligence-series/pwc-consumer-intelligence-series-customer-experience.pdf" className="text-" target="blank">PwC</a>
          </p>
        </div>

      </div>
    </div>


)
