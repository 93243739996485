import React from "react";
import styles from './Legal.module.css'

export const Legal = () => (

    <section className={` pt-8 mb-0 ${styles.bg}`}>
      {/* <div className="container mx-auto px-3"></div> */}
      
      <div className="container mx-auto px-3 border-b border-white pb-8 border-opacity-25">
        <div className="grid sm:grid-cols-2 gap-4 ">
          <div className="text-xs md:text-sm text-white font-light">
            <p className={styles.txt} ></p>
            </div>

            <div className="px-24 sm:px-12">
              <img src={'/investing-in-your-future-white.png'} alt={"Investing in Your future"}  />
            </div>

        </div>
      </div>
    </section>


)
