import React from "react";
import PortableText from "../components/portableText";
import styles from './Testimonial.module.css';

const Icon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={styles.icon}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

const Testimonial = props => {


  return (
    <section className=" px-3">
      <div className="container mx-auto mt-8">


        <div className="w-full">
          <div className={styles.quote}>
            <Icon/><Icon/><Icon/><Icon/><Icon/>
            <div className="text-l"><PortableText blocks={props.quote} /></div>
            <div className="text-sm -mt-4 font-light">{props.name}</div>
          </div>

        </div>
      </div>
          
    </section>
  );
};


export default Testimonial;