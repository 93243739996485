import React from "react";
import styles from './Logos.module.css'

export const Logos = (props) => (
<section className="bg-greenlight">
    <div className="container mx-auto px-3 py-4 pb-0 ">
      <div className="w-full ">

        {/* <h2 className="w-full my-3 text-3xl font-black leading-tight">We are trusted by</h2> */}

        <div className="grid grid-cols-4 lg:grid-cols-8 gap-8 lg:gap-12">
            {props.items.map((item, i) => (
                <div key={i} className={styles.logo}>
                    <img src={item.asset.url} alt={item.alt} className={styles.image} />
                    
                </div>
            ))}
        </div>

      </div>
    </div>

    </section>
)
