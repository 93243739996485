import React from "react";

export const NNGLogo = () => (
  <div className="">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100" height="30" viewBox="0 0 817.000000 388.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,388.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M6192 3810 c-23 -22 -18 -6 -766 -2302 -245 -751 -446 -1380 -446
-1396 0 -17 3 -38 6 -47 8 -20 56 -45 88 -45 61 0 64 7 272 645 108 330 375
1151 595 1824 219 673 399 1239 399 1258 0 71 -95 112 -148 63z"/>
<path d="M52 2123 l3 -1338 232 -3 233 -2 2 867 3 867 384 -844 c211 -465 390
-855 398 -867 13 -23 15 -23 253 -23 l240 0 0 1340 0 1340 -230 0 -230 0 0
-791 c0 -434 -3 -788 -8 -785 -4 2 -167 358 -362 790 l-354 786 -283 0 -283 0
2 -1337z"/>
<path d="M2690 2125 l0 -1335 230 0 230 0 0 860 c0 473 3 860 8 860 4 0 183
-387 397 -860 l390 -859 248 -1 247 0 0 1335 0 1335 -235 0 -235 0 0 -792 c0
-435 -3 -788 -6 -785 -4 3 -166 359 -360 789 l-353 783 -281 3 -280 2 0 -1335z"/>
<path d="M6950 2655 c-90 -18 -191 -58 -255 -100 -245 -163 -336 -504 -203
-759 49 -92 155 -194 257 -244 63 -32 79 -43 65 -48 -50 -18 -140 -57 -163
-72 -79 -49 -128 -120 -138 -202 -15 -128 61 -239 191 -279 61 -19 93 -21 325
-21 349 -1 447 -20 516 -102 43 -51 58 -96 59 -178 1 -140 -49 -225 -166 -280
-156 -74 -470 -60 -630 27 -118 64 -158 204 -98 340 11 26 19 48 17 49 -3 4
-221 -14 -273 -22 -30 -4 -33 -9 -48 -69 -52 -209 19 -380 204 -488 145 -84
452 -131 700 -108 208 20 348 72 454 171 109 103 156 221 157 395 1 176 -58
299 -185 390 -138 98 -217 115 -542 115 -257 0 -313 8 -344 48 -28 35 -25 56
15 101 66 73 240 144 392 160 167 18 276 70 374 180 95 107 149 251 149 402 0
101 -23 189 -70 262 l-30 48 37 -8 c104 -22 227 2 331 64 l63 37 -90 90 -90
89 -68 -34 c-67 -34 -70 -34 -198 -34 -122 1 -137 3 -240 37 -60 20 -137 41
-169 47 -79 14 -225 12 -306 -4z m303 -255 c54 -17 73 -29 110 -70 63 -70 81
-140 75 -297 -5 -134 -27 -201 -89 -262 -60 -61 -108 -76 -240 -76 -113 0
-118 1 -184 35 -108 56 -152 131 -162 284 -15 206 65 352 214 392 75 20 200
17 276 -6z"/>
</g>
</svg>
  </div>
);



