import React from "react";
import PortableText from "../components/portableText";


const TwoColumn = props => {


  return (
    <section className="bg-white py-8 pb-0">
      <div className="container mx-auto m-4 px-3">
        {props.title ? <h1 className="w-full my-2 text-5xl font-black leading-tight">
            {props.title}
        </h1> : null}

        {props.subtitle ? <PortableText blocks={props.subtitle} /> : null}

        {props.firstColumn || props.secondColumn ? <div className="w-full flex flex-col md:flex-row py-6 pb-0">
          {props.firstColumn ? <div className="flex-1 mb-0 md:pr-6">
          
            <PortableText blocks={props.firstColumn} />
          </div>: null}
          {props.secondColumn ? <div className="flex-1 mb-0 md:pl-6">
            <PortableText blocks={props.secondColumn} />
          </div> : null}
        </div> : null}
      </div>
          
    </section>
  );
};


export default TwoColumn;