import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";
import styles from './feature.module.css'
// import "../styles/hero.css";


import { getFluidGatsbyImage } from "gatsby-source-sanity";
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <img className="w-full z-50" src={fluidProps.src} alt={illustration.image.alt} />
    );
  }
  return img;
};

function Feature(props) {
  const img = maybeImage(props.illustration);
  return (
    <div className={styles.hero}>
    <div className={`container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center hero`}>
      {/* Left col */}
      <div className="flex flex-col w-full md:w-3/5 justify-center items-start md:text-left">
        <p className="uppercase tracking-loose w-full">{props.label}</p>
        <h1 className={`my-4 text-5xl font-black leading-tight ${styles.h1}`}>{props.heading}</h1>
        {props.tagline ? <div  className={`leading-normal text-2xl md:text-4xl md:pr-16 mb-8 ${styles.tagline}`} >
          <PortableText blocks={props.tagline} />
        </div> : null }
        {props.body ? <div  className={`leading-normal text-xl md:pr-16 mb-8 ${styles.body}`} >
          <PortableText blocks={props.body} />
        </div> : null }
        {props.cta && props.cta.title && (
          <CTALink
            {...props.cta}
            buttonActionClass={`mx-auto md:mx-0 hover:underline font-bold rounded-full my-6 py-4 px-8 shadow-lg ${styles.heroCTA}`}
          />
        )}
      </div>
      {/* Right col */}
      <div className="w-full md:w-2/5 text-center">
          {img}
          <p className="py-3">{props.illustration.image.caption}</p>
    </div>
    </div>
    </div>
  );
}

export default Feature;
