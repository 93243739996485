import React from "react";

export const Subscribe = () => (

    <div className="container mx-auto px-3">
      <div className="w-full flex flex-col md:flex-row">

        
      </div>
    </div>


)
