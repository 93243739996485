import React, { useLayoutEffect } from "react";
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import CTALink from "./CTALink";
// import ShopifyBuy from "@shopify/buy-button-js"
import PortableText from "./portableText";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
// import styles from './about.module.css'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { render } from "react-dom";

function addWorkDays(date, days) {   
  while (days > 0) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() != 0 && date.getDay() != 6) {
      days -= 1;
    }
  }          
  return date;
}

const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {

    img = (


      <img
      src={imageUrlFor(buildImageObj(illustration.image))
        .width(1200)
        .height(Math.floor((16 / 10) * 1200))
        .fit('crop')
        .auto('format')
        .url()}
      alt={illustration.image.alt}
      className="inset-y-0 absolute object-cover h-full w-full"
    />

    );
  }
  return img;
};


// const Form = ({ title, text, illustration, cta, fieldlabel }) => {
class Form extends React.Component {

  state = {
    email: null,
    website: null,
    msg: null,
    loading: false
}

_handleChange = e => {
    // console.log({
    //     [`${e.target.name}`]: e.target.value,
    // })
    if(!this.state.email && !this.state.website) {
      window.dataLayer.push({'event': 'subscriber-form-started'});
    }
    this.setState({
        [`${e.target.name}`]: e.target.value,
    })
}
_handleCloseMessage = e => {

    this.setState({
        msg: null,
    })
}

_handleSubmit = e => {
    e.preventDefault()
    this.setState({
        msg: null,
        loading: true
    })
    
    // this.setState({
    //   msg: {success: false, content: "Lūdzu ievadi pareizu e-pastu, vai arī šis e-pasts jau ir pierakstīts informācijas saņemšanai."},
    //   loading: false
    // })
    // console.log('submit', this.state)

    let websiteURL;
    if(!this.state.website.startsWith("https://")) {
      websiteURL = `https://${this.state.website}`
    }

      addToMailchimp(this.state.email, {WEBSITE: websiteURL})
        .then(({ msg, result }) => {
            // console.log('msg', `${result}: ${msg}`, msg, result)

            if (result !== 'success') {
                throw {success: false, content: msg}
            }
            // alert(msg)
            window.dataLayer.push({'event': 'new-subscriber'});
            throw {success: true, content: 'Thank you for applying for a free audit. We will start it soon.'}
        })
        .catch(err => {
            // console.log('err', err)

            this.setState({
                msg: err,
                loading: false
            })
            // alert(err)
        })
}

render() {
  
  const img = maybeImage(this.props.illustration);
  const sizeClass = img ? "sm:w-1/1" : "sm:w-1/1";

  // let now = new Duate()
  // let date = addWokrarkDays(now, 1)
// i

// navigator

  return (
    <section className={`bg-green`} id="contact">
       {/* {date} */}
      <div className="container mx-auto text-gray-800">

        <div className="w-full mb-0">
          
          <div className="sm:grid sm:grid-cols-12 gap-8">
          
            {img ? <div className="hidden sm:block sm:relative col-span-4 col-start-2">
              
            {img && <div className={"w-full h-full inset-y-0 sm:relative " + sizeClass}>{img}</div>}

            </div> : null}

            <div className={img ? "col-span-5 px-3 sm:px-0 pb-3" : "col-span-6 col-start-4 px-3 sm:px-0 pb-3"}>
                  <h2 className="w-full my-2 text-3xl font-black pt-8 leading-tight">
                  {this.props.title}
                </h2>       

                <div className="grid ">
                  <div className="text-xl">
                  <PortableText blocks={this.props.text} />
                    </div>
                </div>

                

                {/* <p className="mb-4">You will receive a <strong>free report in two business days</strong>. </p> */}

                <form onSubmit={this._handleSubmit}>

                {this.state.msg ? <div class={this.state.msg.success ? " bg-white p-4 rounded-md mb-4" : "bg-white p-4 rounded-md mb-4"}>
                  <div className="font-bold pb-4">
                    <p>{this.state.msg.success ? "Thank you!" : "Error"}</p>
                  </div>
                  <div className="">
                    {this.state.msg.content}
                    <button className="bg-purple mt-4 text-white font-bold rounded-md py-4 px-8 shadow block" aria-label="close" onClick={this._handleCloseMessage}>Return</button>
                  </div>
                </div> : null}


                {this.state.msg ? null : <div>

                  <div className="relative mb-4 w-full">
                    <label className="absolute left-1 top-0.5  text-gray-800 font-bold text-xs select-none pointer-events-none">{this.props.fieldlabel}</label>
                    <div className="absolute left-1 top-2 text-gray-500 text-xl select-none pointer-events-none">https://</div>
                    <input name="website" className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-xl rounded-md py-4 pl-24 pt-8 shadow-md" type="text" aria-label="Filter projects" onChange={this._handleChange}  required />
                  </div>

                  <div className="relative mb-4 w-full">
                    <label className="absolute left-1 top-0.5  text-gray-800 font-bold text-xs select-none pointer-events-none" for="url">Your e-mail address</label>
                    <input name="email" className={this.state.loading ? "focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-xl rounded-md py-4 pl-4 pt-8 shadow-md placeholder-text-gray-500 is-loading": "focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-xl rounded-md py-4 pl-4 pt-8 shadow-md placeholder-text-gray-500"}  type="email" placeholder="Enter your e-mail" aria-label="Filter projects"  onChange={this._handleChange} required />
                  </div>

                  <input type="submit" disabled={this.state.loading} value={this.state.loading ? "Loading..." : this.props.cta} className={this.state.loading ? "hover:underline font-bold rounded-md py-4 px-8 shadow bg-gray text-white mb-4 block w-full" : "hover:underline font-bold rounded-md py-4 px-8 shadow bg-purple text-white mb-4 block w-full"} />

                  <p className="text-sm text-center"><span className="font-bold">No payment required - We promise, no spam</span> < br /> You agree to be contacted by Unicorn Audit CRO specialist according to our privacy policy.</p>
                
                </div>}


                </form>


            </div>
          </div>

          





        </div>




      </div>
    </section>
  );
};

}

export default Form;
