import React from "react";
import PortableText from "./portableText";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
// import Testimonial from "./Testimonial";


const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    // const fixedProps = getFixedGatsbyImage(
    //   illustration.image.asset._id,
    //   { width: 960, height: 500 },
    //   clientConfig.sanity
    // );


    img = (


      <img
      src={imageUrlFor(buildImageObj(illustration.image))
        .width(1200)
        .height(Math.floor((12 / 16) * 1200))
        .fit('crop')
        .auto('format')
        .url()}
      alt={illustration.image.alt}
    />
      // <img className="w-full mx-auto" src={fixedProps.src} alt={illustration.image.alt} />
    );
  }
  return img;
};


const CaseStudy = props => {
  const img = maybeImage(props.illustration);
  const sizeClass = img ? "sm:w-1/1" : "sm:w-1/1";


  console.log(props)

    return (
        <a href={props.cta ? props.cta.route : "#" } className="bg-greenlight mb-8 lg:mb-0">

            {img && <div className={"w-full " + sizeClass}>{img}</div>}
            <div className={"w-1/1 p-4 bg-white " + sizeClass}>
              <h3 className="text-xl font-bold leading-none mb-3 mt-3">{props.title}</h3>
              <div className="mb-2 md:pr-10 text-md">
                <PortableText blocks={props.text} />
              </div>
              <h3 className=" font-bold leading-none mb-4 md:pr-10">{props.subtitle}</h3>
              <h3 className="text-5xl font-light text-purple leading-none mb-3  md:pr-10">{props.result}</h3>

              {props.cta ? <div className="text-purple font-bold mb-4">Read article</div> : null}

            </div>

        </a>
    )
}

const ClientCases = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return <CaseStudy key={r._key} {...r} />;
    });

  return (
    <section className="  bg-greenlight overflow-hidden">
      <div className="container mx-auto px-3 pt-6">
        { props.title ? <h2 className="w-full my-2 text-3xl font-black leading-tight mb-8">{props.title}</h2> : null }
        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        {contentRows}
          </div>
      </div>
    </section>
  );
};

export default ClientCases;
