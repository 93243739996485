import React from "react";
import PortableText from "./portableText";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Testimonial from "./Testimonial";

const TestimonialList = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return <Testimonial key={r._key} {...r} />;
      // return i % 2 === 0 ? <InfoRow key={r._key} {...r} /> : <InfoRowFlipped key={r._key} {...r} />;
    });

  return (
    <section className="pt-0 bg-greenlight overflow-hidden pb-8">
      <div className="container mx-auto px-3"> 
        { props.title ? <h2 className="w-full my-2 text-3xl font-black leading-tight">{props.title}</h2> : null }
        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        {contentRows}
          </div>
      </div>
    </section>
  );
};

export default TestimonialList;
