import React from "react";

export const Stats2 = () => (

  <section className="bg-white  mb-0 bg-greylight" id="pricing">
    {/* <div className="container mx-auto px-3"></div> */}

    <div className="container mx-auto px-3 py-4 pt-12 ">
          
      <h2 className="my-2 text-4xl font-bold leading-tight">Website audit pricing</h2>

      <div className=" p-4">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-8">


        <div className="md:col-span-3 md:py-8 text-center">


        <p className=" font-light bg-green rounded-full h-64 w-64 block flex flex-col items-center justify-center shadown-lg mx-auto ">
            
            <span className="text-5xl md:text-5xl">€ 790</span>
            <span className="">Full website audit</span>
          </p>

          <p className=" font-light bg-gray-600 text-white -mt-16 mr-8 rounded-full h-40 w-40 block flex flex-col items-center justify-center shadown-lg mx-auto ">
            
            <span className="text-3xl md:text-3xl ">€ 250</span>
            <span className="">Single page audit</span>
          </p>


          {/* <div className=" md:mt-4 text-left hidden lg:block">
            <p className="mb-2 md:mb-6 pr-6">For businesses interested in full website CRO audit</p>
            <p>Are you curios of what website audit checks in a site? We have prepared a small demo for you.</p>
            <p>
              <a href="/contact" className="inline-block mx-auto text-center hover:underline font-bold rounded-md mt-6 py-4 px-8 shadow-lg text-white bg-purple">Contact us</a>
              <a href="https://reports.unicornaudit.com/customer/GlABNVRyGrWjk93q?utm_source=website&utm_medium=link&utm_campaign=demo" className="inline-block text-center hover:underline font-bold rounded-md  py-4 px-8 text-purple " rel="nofollow" target="blank">Preview audit demo</a>
            </p>
          </div>


          <div className=" md:mt-4 text-center  lg:hidden">
            <p className="mb-2 md:mb-6 pr-6">For businesses interested in full website CRO audit. Are you curios of what website audit checks in a site? Get a free audit trial.</p>
            <p>
              <a href="/contact" className="inline-block mx-auto text-center hover:underline font-bold rounded-md mt-6 py-4 px-8 shadow-lg text-white bg-purple">Contact us</a>
              <a href="/get-cro-audit" className=" inline-block text-center hover:underline font-bold rounded-md  py-4 px-8 text-purple" >Get audit trial</a>
            </p>
          </div> */}




        </div>

        <div className="md:col-span-3 bg-greylight text-center md:text-left md:p-6">
          <p className="mb-2 font-bold">What you will get</p>
          <ul className="list-disc list-inside leading-loose">
            <li>Usability analysis</li>
            <li>Sales funnel analysis</li>
            <li>Full site review</li>
            <li>Mobile and desktop testing</li>
            <li>CRO action plan</li>
            <li>Expert consultation</li>
          </ul>
          <p><a href="/get-cro-audit" className=" inline-block mx-auto text-center hover:underline font-bold rounded-md mt-6 py-4 px-8 shadow-lg text-white bg-purple" >Submit your site</a></p>
          <p className="mt-4">Risk-free trial - No payment required</p>
        </div>

      </div>

      </div>

    </div>
  </section>


)
