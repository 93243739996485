import React from "react";
import PortableText from "./portableText";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    // const fixedProps = getFixedGatsbyImage(
    //   illustration.image.asset._id,
    //   { width: 960, height: 500 },
    //   clientConfig.sanity
    // );


    img = (


      <img
      src={imageUrlFor(buildImageObj(illustration.image))
        .width(1200)
        .height(Math.floor((12 / 16) * 1200))
        .fit('crop')
        .auto('format')
        .url()}
      alt={illustration.image.alt}
    />
      // <img className="w-full mx-auto" src={fixedProps.src} alt={illustration.image.alt} />
    );
  }
  return img;
};

const InfoRow = props => {
  const img = maybeImage(props.illustration);
  const sizeClass = img ? "sm:w-1/1" : "sm:w-1/1";

  return (
    <div className={""}>
      {img && <div className={"w-full " + sizeClass}>{img}</div>}
      <div className={"w-1/1 " + sizeClass}>
        <h3 className="text-2xl font-black leading-none mb-3 ">{props.title}</h3>
        <div className="">
          <PortableText blocks={props.text} />
        </div>
      </div>
    </div>
  );
};

// const InfoRowFlipped = props => {
//   const img = maybeImage(props.illustration);
//   const sizeClass = img ? "sm:w-1/2" : "sm:w-1/1";

//   return (
//     <div className={"flex flex-wrap pb-6 flex-col-reverse sm:flex-row md:w-2/3 mx-auto md:mt-16"}>
//       {img && <div className={"w-full " + sizeClass}>{img}</div>}
//       <div className={"w-5/6  " + sizeClass}>
//         <h3 className="text-2xl font-black leading-none mb-3  md:pl-10">{props.title}</h3>
//         <div className=" mb-8 md:pl-10">
//           <PortableText blocks={props.text} />
//         </div>
//       </div>
//     </div>
//   );
// };

const InfoRows = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return <InfoRow key={r._key} {...r} />;
      // return i % 2 === 0 ? <InfoRow key={r._key} {...r} /> : <InfoRowFlipped key={r._key} {...r} />;
    });

  return (
    <section className="">
      <div className="container mx-auto px-3">
        { props.title ? <h2 className="w-full text-3xl font-black leading-tight mb-8">{props.title}</h2> : null }
        <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 gap-8 gap-y-2">
        {contentRows}
          </div>
      </div>
    </section>
  );
};

export default InfoRows;
