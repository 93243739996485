import React, { useLayoutEffect } from "react";
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';



const BeforeAfterComparison = ({ title, description }) => {

  return (
    <section className="">
       


        <ReactCompareSlider
            boundsPadding={40}
            handle={<ReactCompareSliderHandle buttonStyle={{WebkitBackdropFilter: undefined, backdropFilter: undefined, backgroundColor: '#F2654B', border: 0, boxShadow: undefined, color: '#fff'}} linesStyle={{opacity: 1, backgroundColor: '#F2654B', width: '8px'}}/>}
            onlyHandleDraggable
            itemOne={<ReactCompareSliderImage src="/before.jpg" alt="Before" />}
            itemTwo={<ReactCompareSliderImage src="/after.jpg" alt="After" />}
        />


    </section>
  );
};

export default BeforeAfterComparison;
