import React, { useLayoutEffect } from "react";
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import CTALink from "./CTALink";
// import ShopifyBuy from "@shopify/buy-button-js"
import PortableText from "./portableText";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import styles from './about.module.css'

const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {

    img = (


      <img
      src={imageUrlFor(buildImageObj(illustration.image))
        .width(1200)
        .height(Math.floor((16 / 9) * 1200))
        .fit('crop')
        .auto('format')
        .url()}
      alt={illustration.image.alt}
    />

    );
  }
  return img;
};


const About = ({ title, text, illustration }) => {

  const img = maybeImage(illustration);
  const sizeClass = img ? "sm:w-1/1" : "sm:w-1/1";



  return (
    <section className={` py-8  bg-greenlight`}> {/* bg-greylight */}
       
      <div className="container mx-auto text-gray-800">

        <div className={`${styles.wrapper} w-full mb-4`}>
          
          <div className="sm:grid sm:grid-cols-2 gap-8 px-3">
          
            {/* <div> */}
              
            {/* {img && <div className={"w-full " + sizeClass}>{img}</div>} */}

            {/* </div> */}
            <div className="col-span-2 sm:px-0">
                  <h2 className="w-full my-2 text-3xl pt-8 font-black leading-tight">
                  {title}
                </h2>       

                <div className="grid md:grid-cols-2 ">
                    <div className="">
                      <PortableText blocks={text} />
                    </div>
                </div>
                

                
            </div>
          </div>

          





        </div>




      </div>
    </section>
  );
};

export default About;
