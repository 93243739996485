import React from "react";
import { Link } from "gatsby";
import styles from './Example.module.css'

const Annotation = (props) => (
    <div className={`${styles.annotation}`} style={props.style}>
      <div className={`${styles.annotationIconWrapper}`}>{props.icon}</div>
      <div className={`shadow-lg text-center text-sm lg:text-base ${styles.annotationText}`}>{props.text}</div>
    </div>
  )
  
const Example = (props) => (
    <div className={`pb-40 ${styles.example} md:pb-0 overflow-x-hidden `}>
      <div className="relative ">
        <img src="/laptop-with-site.png" alt="A sample of a website" className="block" />
        <Annotation text="Product categories missing" style={{left: "3%", top: "8%"}} icon={<div className={`material-icons shadow-lg ${styles.annotationIcon} ${styles.annotationIconRed}`}>error_outline</div>} />
        <Annotation text="Low contrast in CTA button" style={{left: "30%", top: "43%"}} icon={<div className={`material-icons shadow-lg ${styles.annotationIcon} ${styles.annotationIconRed}`}>error_outline</div>} />
        <Annotation text="Product image not linked" style={{left: "56%", top: "74%"}} icon={<div className={`material-icons shadow-lg ${styles.annotationIcon} ${styles.annotationIconRed}`}>error_outline</div>} />
      </div>
    </div>
  )
  

  export default Example;
