import React, { useLayoutEffect } from "react";
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import CTALink from "./CTALink";
// import ShopifyBuy from "@shopify/buy-button-js"

// import { Base64 } from 'js-base64'
import PortableText from "../components/portableText";
import BeforeAfterComparison from './beforeaftercomparison'

const BeforeAfter = ({ title, description }) => {
  // const btnClassName = transparentCTAs
  //   ? "mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
  //   : "mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg";






  //   useLayoutEffect(() => {

  //     if (typeof window === "undefined" || !window.document) {
  //       // console.log(`bailing out of the useeffect. Going to continue to render??`)
  //       return
  //     }


  //     const client = ShopifyBuy.buildClient({
  //         domain: 'unicorn-audit.myshopify.com',
  //         storefrontAccessToken: '21896d7da97f4a99e76f972b9e90abca'
  //     })
  //     const ui = ShopifyBuy.UI.init(client)
  //     // const decodded = Base64.decode("5965053558941")
  //     // const acutalId = decodded.replace("gid://shopify/Product/", "")
  //     ui.createComponent("product", {
  //         id: 5965053558941,
  //         options: {
  //           product: {
  //             buttonDestination: 'checkout',
  //             contents: {
  //               title: false,
  //               price: false
  //             },
  //             text: {
  //               button: 'Buy Now!'
  //             },
  //             styles: {
  //               button: {
  //                 'color': '#6BFC6D',
  //                 'background-color': '#3E147B',
  //                 'border-radius': '50px',
  //                 ':hover': {
  //                   'color': '#fff',
  //                   'background-color': '#3E147B',

  //                 }
  //               }
  //             }
  //           },
            
  //         },
  //         node: document.getElementById("button")
  //     })
  // })



  return (
    <section className="">
       
      <div className="container mx-auto px-3 py-12 ">

        <div className="w-full mb-4">
          
          <div className="sm:grid sm:grid-cols-2 gap-8">
          
            <div className="pb-4 lg:pb-0">
              
                    <h2 className="w-full my-2 text-3xl font-black leading-tight mb-8">
                  {title}
                </h2>
            <div className="text-lg pb-4"><PortableText blocks={description} /> </div>

                <div className="pt-2 hidden md:block">
                  <a href="/get-cro-audit" className={`bg-purple text-white hover:underline font-bold  text-center rounded-md my-6 py-4 px-8 shadow-lg`}>Start your audit today</a>
                </div>            

            </div>
            <div>



                <BeforeAfterComparison />

                <p className="font-bold mt-6">Compare the before &amp; after the audit</p>

                <div className="pt-6 block md:hidden">
                  <a href="/get-cro-audit" className={`bg-purple text-white hover:underline font-bold block  text-center rounded-md my-6 py-4 px-8 shadow-lg`}>Start your audit today</a>
                </div>            


            </div>
          </div>

          





        </div>




      </div>
    </section>
  );
};

export default BeforeAfter;
