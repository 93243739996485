import React from "react";


const PageHeader = props => {


  return (
    <section className="bg-white py-8">
      <div className="container mx-auto m-8 px-3">
        <h1 className="w-full my-2 text-5xl font-black leading-tight">
          {props.title}
        </h1>

      </div>
    </section>
  );
};


export default PageHeader;