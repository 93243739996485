import React from "react";
import {GoogleLogo} from './icon/google.js'
import {UXBoothLogo} from './icon/uxbooth.js'
import {NNGLogo} from './icon/nng.js'

export const Compare = () => (

    <section className="bg-white py-8  mb-8">
      {/* <div className="container mx-auto px-3"></div> */}
      
      <div className="container mx-auto px-3  ">
        <div className="w-full flex flex-col md:flex-row">


          <div className="flex-1 text-left pb-6 ">
            <div className="text-2xl ">400 factors</div>

              based on psychology and research. They include learnings from industry leaders

            <div className="flex flex-row mt-3">
                <div className="flex-1 opacity-50"><GoogleLogo /></div>
                <div className="flex-1 opacity-50"><UXBoothLogo /></div>
                <div className="flex-1 opacity-50"><NNGLogo /></div>
            </div>

          </div>

          <div className="flex-1 text-left pb-6">
              
          <div className="text-2xl ">10+ year experience</div>

            Independent experts with professional background in marketing, sales, design and online technology
          </div>

          <div className="flex-1 text-left pb-6">
                <div className="text-2xl">
                    <span className="text-2xl">An audit for €450</span>
                </div>
                Before it cost thousands, but we make the same result available to companies of all sizes 
          </div>

        </div>
      </div>
    </section>


)
