import React from "react";
import PortableText from "../components/portableText";
import CTALink from "./CTALink";
import styles from './cta.module.css'

const CTA = ({ label, title, body, ctas }) => (
  <section className={`container mx-auto text-center p-4 mb-12 md:mb-16`}>
    <div className={`p-6 md:shadow-2xl ${styles.cta}`}>
    <h2 className="w-full my-2 text-3xl font-black leading-tight text-center ">{title}</h2>
    <div className="w-full mb-4">
      <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
    </div>

    <div className="mt-4 text-3xl leading-tight mx-auto w-3/5">
      <PortableText blocks={body} />
    </div>

    <div className="flex">
      {(ctas || []).map((c, i) => (
        <div className="flex-1  text-center py-2" key={i}>
          <CTALink
            key={`cta_${i}`}
            {...c}
            buttonActionClass={`mx-auto ml-4 hover:underline font-bold rounded-full  py-4 px-8 shadow-lg ${styles.button}`}
          />
        </div>
      ))}
    </div>
    </div>
  </section>
);

export default CTA;
