import React from "react";
import PortableText from "./portableText";

const CTAColumn = ({ width, label, title, body, icon, ctas = [] }) => {
  const className = `w-1/2 md:w-1/${width} p-6 pb-0 flex flex-col flex-grow flex-shrink`;

  const actions = ctas
    .filter(c => c.title)
    .map(c => {
      return (
        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
          <div className="flex items-center justify-start">
            <button className="mx-auto hover:underline gradient font-bold rounded-full my-6 py-4 px-8 shadow-lg">
              {c.title}
            </button>
          </div>
        </div>
      );
    });

  return (
    <div className={className}>
      <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden ">
        {/* <a href="#" className="flex flex-wrap no-underline hover:no-underline"> */}
          {icon ? <img src={icon.asset.url} alt={icon.alt} className="" style={{maxWidth: 70, display: 'block', boxSizing: 'paddingBox'}} /> : null}
          <p className="w-full text-gray-600 text-xs md:text-sm ">{label}</p>
          <div className="w-full font-bold text-xl text-gray-800 pt-6">{title}</div>
          <div className="text-gray-800 text-base ">
            <PortableText blocks={body} />
          </div>
        {/* </a> */}
      </div>
      {actions}
    </div>
  );
};

const CTAColumns = ({ title, columns }) => {
  const cols = columns
    .filter(c => !c.disabled)
    .map((c, i) => {
      return <CTAColumn width={columns.length} key={c._key} {...c} />;
    });

  return (
    <section className="bg-white ">
      <div className="container mx-auto flex flex-wrap pt-4 ">
      <h2 className="w-full my-3 text-3xl font-black leading-tight px-3">{title}</h2>


        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        {cols}
      </div>
    </section>
  );
};

export default CTAColumns;
