import React from "react";
import styles from './What.module.css'

const itemText = [
  // 'In-depth study on what impacts your customer behaviour',
  // 'Understand why customers leave without buying',
  // // 'Understand how they navigate through the site and why they abandon shopping cart',
  // 'Tests the whole site and how it works on mobile and on desktop',
  // 'Detailed and easy to review report',
  // 'Get CRO audit results in a few days',
  // 'Make sense of your stats and heatmaps',
  // 'Know what are the most important improvement',
  // 'Get individual coaching on website audit results',
  'Increase conversion rate by picking low hanging fruits',
  'Earn more by improving sales funnel',
  'Convert visitors into buyers'
  // 'Get a clear action plan and expert consultation'
]

const Item = (props) => (
  <li className={` bg-purple text-greenlight p-4 ${styles.checmark}`}>
    <div className="flex py-1  ">
      <div className={`material-icons mt-1 ${styles.icon}`}>task_alt</div>
      <div className="flex-1 text-xl md:text-2xl ">{props.title}</div>
    </div>
    
  </li>
)



export const What = () => (

    <section className="mb-0 bg-greenlight pb-4 ">
      <div className="container mx-auto px-3">
        
        <h2 className="md:w-3/5 text-4xl font-black leading-tight pt-6 pb-2 ">CRO audit is <span className={styles.subtitle}>unbiased analysis of your website</span></h2> 



          <ul className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-6">
            {itemText.map(item => <Item title={item} />)}

          </ul>


{/* 
        <div className="text-purple text-3xl font-light text-center pb-6 md:w-1/2 mx-auto">
        We will find the reason for drop-offs and abandoned carts
        </div> */}



      </div>
    </section>

)
