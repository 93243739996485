import React from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";
import styles from './hero.module.css'
// import "../styles/hero.css";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import BeforeAfter from "./beforeaftercomparison"

import Example from "./Example";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
const maybeImage = illustration => {
  let img = null;
  if (illustration && illustration.image && illustration.image.asset && !illustration.disabled) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = (
      <img className="w-full md:w-4/5 mx-auto z-50" src={fluidProps.src} alt={illustration.image.alt} />
    );
  }
  return img;
};


function Hero(props) {
  const img = maybeImage(props.illustration);

  // const bgUrl = props.background ? imageUrlFor(buildImageObj(props.background)).width(1200).height(Math.floor((9 / 16) * 1200)).fit('crop').auto('format').url() : null;
  // const style = props.background ? {backgroundImage: `url('${bgUrl}')`} : {};
  const bgUrl = props.background ? imageUrlFor(buildImageObj(props.background)).width(1200).height(Math.floor((9 / 16) * 1200)).fit('crop').auto('format').url() : null;
  const style = props.background ? {} : {};

  return (
    <div className={`-mt-32 ${props.background ? styles.heroBg : styles.hero}`} style={style}>
      {/* <div className={`pointer-events-none ${styles.spark}`}></div> */}
      <div className={styles.wrapper}>
        <div className={`container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center hero `}>
          {/* Left col */}
          <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">




            <p className="uppercase tracking-loose w-full">{props.label}</p>
            <h1 className={`my-4 text-4xl lg:text-5xl font-black leading-tight ${styles.h1}`}>{props.heading}</h1>
            {props.tagline ? <div className="leading-normal text-2xl mb-8">
              <PortableText blocks={props.tagline} />
            </div> : null }
            {props.cta && props.cta.title && (
              <div className="w-full text-center md:text-left mt-4">
              <CTALink
                {...props.cta}
                smoothScroll
                buttonActionClass={`mx-auto hover:underline font-bold rounded-md my-6 py-4 px-8 shadow-lg  ${styles.heroCTA}`}
                />
                </div>
            )}
          </div>


          {/* Right col */}
          {img ? <div className="w-full md:w-3/5 py-6 text-right">{img}</div> : null }

          {/* <div className={`w-full md:w-3/5 flex-grow hidden lg:block relative hero__cards ${styles.hero__cards}`} style={{minHeight: 300}}>
           


              <div className={`w-2/5 rounded-lg bg-white shadow-xl text-black text-center p-6 absolute ${styles.hero__cards__item}`} style={{top: '50%', left: '50%'}}>
                  
                  <div class={styles.hero__cards__label}>Checkout flow too tedious</div>
                  </div>
              <div className={`w-2/5 rounded-lg bg-white shadow-xl text-black text-center p-6 absolute ${styles.hero__cards__item}`} style={{top: '50%', left: '50%'}}>
              <div class={styles.hero__cards__label}>Missed upsell opportunity</div>
                </div>
              <div className={`w-2/5 rounded-lg bg-white shadow-xl text-black text-center p-6 absolute  ${styles.hero__cards__item}`} style={{top: '50%', left: '50%'}}>
              <div class={styles.hero__cards__label}>No support found</div>
                
                </div>


          </div> */}


          <div className={`w-full md:w-3/5 mt-12 lg:mt-12 `} style={{minHeight: 0}}>
            <Example />
            {/* <div className={`shadow-xl ${styles.hero__beforeafter}`}>
              <BeforeAfter />
            </div> */}

            {/* <div className=" mt-4"> grid grid-cols-2 */}
              {/* <div ><span className="font-bold">Compare the before &amp; after </span> -  <a href="/blog/first-impression-of-your-website-matters/" className="text-blue underline">Read our case study</a> </div> */}
              {/* <div className="font-bold">Before audit</div>
              <div className="font-bold text-right">After audit</div> */}
            {/* </div> */}
           
          </div>


        </div>
        </div>
    </div>
  );
}

export default Hero;
