import React from "react";

export const UXBoothLogo = () => (
  <div className="">
<svg version="1.1" viewBox="0 0 634.092 94.785" width="100" height="30">

	<path fill="#000" d="M172.404,0c-9.806,0-20.24,5.842-29.629,21.702l-5.424,9.581L126.708,2.471H98.953l-5.006,20h17.736
		l11.684,27.995l-7.094,10.45c-5.424,7.929-10.433,12.527-13.98,12.736c-1.879-3.339-5.426-5.942-10.225-5.942
		c-6.886,0-12.52,5.584-12.52,12.469c0,8.137,6.886,14.581,17.319,14.581c10.433,0,20.867-5.855,29.631-20.878l5.632-9.814
		l5.219,13.143c5.007,12.936,11.684,17.525,20.657,17.525c10.643,0,15.369-4.588,16.243-8.598
		c-0.549-0.159-11.379-1.028-18.33-17.485l-9.597-23.371l6.467-10.435c4.382-7.302,10.433-13.144,13.146-13.563
		c1.877,3.339,5.217,5.844,10.224,5.844c6.886,0,12.521-5.636,12.521-12.521C188.682,6.469,181.795,0,172.404,0z"></path>
	<path fill="#000" d="M72.994,80.322c0-0.27,0.025-0.944,0.026-0.974c0.136-2.835,2.054-11.298,3.054-15.095L92.022,2.471
		H68.651L55.296,55.488c-3.339,13.355-9.181,20.248-20.24,20.248c-7.095,0-11.269-4.691-11.269-13.036
		c0-2.504,0.627-4.866,1.253-7.786L38.185,2.471H14.802l-4.786,18.796L2.295,52.576C0.834,58.835,0,64.787,0,69.585
		c0,15.858,10.85,25.199,22.745,25.199c11.685,0,22.953-7.954,30.465-20.265c0.209,12.311,7.303,20.228,18.99,20.228
		c2.211,0,4.206-0.291,6.504-0.933C75.285,90.309,72.994,85.638,72.994,80.322z"></path>

	<path fill="#000" d="M217.216,80.882h10.564V13.913h-10.564v-6h31.686c18.479,0,25.564,9.723,25.564,19.681
		c0,9.606-6.122,16.202-14.883,18.841c10.798,2.766,17.16,8.766,17.16,19.085c0,12.118-9.841,21.362-26.404,21.362h-33.123V80.882z
		 M247.221,43.679c13.92,0,20.522-6.362,20.522-15.606c0-8.756-6.601-14.16-19.08-14.16H234.62v29.766H247.221z M250.583,80.882
		c12.718,0,19.442-6.841,19.442-15.479c0-10.564-8.643-15.968-22.803-15.968H234.62v31.447H250.583z"></path>
	<path fill="#000" d="M291.309,47.519c0-23.17,17.043-41.043,39.963-41.043c22.564,0,39.484,18,39.484,40.799
		c0,23.16-17.043,41.043-39.963,41.043C308.229,88.318,291.309,70.318,291.309,47.519z M363.794,47.519
		c0-19.447-13.437-34.809-32.761-34.809s-32.766,15.245-32.766,34.564c0,19.447,13.442,34.809,32.766,34.809
		S363.794,66.839,363.794,47.519z"></path>
	<path fill="#000" d="M385.209,47.519c0-23.17,17.043-41.043,39.963-41.043c22.564,0,39.485,18,39.485,40.799
		c0,23.16-17.043,41.043-39.963,41.043C402.13,88.318,385.209,70.318,385.209,47.519z M457.695,47.519
		c0-19.447-13.437-34.809-32.761-34.809s-32.766,15.245-32.766,34.564c0,19.447,13.442,34.809,32.766,34.809
		S457.695,66.839,457.695,47.519z"></path>
	<path fill="#000" d="M493.153,80.882h11.16V13.669H482.11v12.723h-6.601V7.913h64.448v18.479h-6.601V13.669h-22.202
		v67.213h11.165v6h-29.165V80.882z"></path>
	<path fill="#000" d="M552.241,80.882h10.564V13.913h-10.564v-6h27.963v6h-10.559V43.2h47.043V13.913h-10.559v-6h27.963v6
		h-10.564v66.969h10.564v6h-27.963v-6h10.559V49.318h-47.043v31.564h10.559v6h-27.963V80.882z"></path>

</svg>
  </div>
);



