import React, { useLayoutEffect } from "react";
import CTALink from "./CTALink";
// import ShopifyBuy from "@shopify/buy-button-js"

// import { Base64 } from 'js-base64'

const Pricing = ({ title, transparentCTAs }) => {
  const btnClassName = transparentCTAs
    ? "mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
    : "mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg";






  //   useLayoutEffect(() => {

  //     if (typeof window === "undefined" || !window.document) {
  //       // console.log(`bailing out of the useeffect. Going to continue to render??`)
  //       return
  //     }


  //     const client = ShopifyBuy.buildClient({
  //         domain: 'unicorn-audit.myshopify.com',
  //         storefrontAccessToken: '21896d7da97f4a99e76f972b9e90abca'
  //     })
  //     const ui = ShopifyBuy.UI.init(client)
  //     // const decodded = Base64.decode("5965053558941")
  //     // const acutalId = decodded.replace("gid://shopify/Product/", "")
  //     ui.createComponent("product", {
  //         id: 5965053558941,
  //         options: {
  //           product: {
  //             buttonDestination: 'checkout',
  //             contents: {
  //               title: false,
  //               price: false
  //             },
  //             text: {
  //               button: 'Buy Now!'
  //             },
  //             styles: {
  //               button: {
  //                 'color': '#6BFC6D',
  //                 'background-color': '#3E147B',
  //                 'border-radius': '50px',
  //                 ':hover': {
  //                   'color': '#fff',
  //                   'background-color': '#3E147B',

  //                 }
  //               }
  //             }
  //           },
            
  //         },
  //         node: document.getElementById("button")
  //     })
  // })



  return (
    <section className="bg-green py-8" id="pricing">
       
      <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
        <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          {title}
        </h1>
        <div className="w-full mb-4">
          
        <h2 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
          1. Free consultation
        </h2>
        <p>Get a free consultation with </p>

        <ul>
          <li>Expert review of online store </li>
          <li>Improve conversion rate</li>
          <li>Access interactive platform</li>
        </ul>


        <h2 className="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
          2. Receive full report
        </h2>

        <ul className="w-full text-center text-sm">
          <li className="">Whole site analysis regardless the size</li>
          <li className="">10 improvements</li>
          <li className="">200 factor analysis</li>
          <li className="">Desktop &amp; Mobile analysis</li>
          <li className="">60 min free consultation</li>
        </ul>

        <div className="flex items-center justify-center">
          <a href="/contact" className={btnClassName}>Contact us</a>
        </div>

        </div>




      </div>
    </section>
  );
};

export default Pricing;
